.card {
  .card-category,
  label {
    font-size: 1rem;
    font-weight: 400;
    //color: $dark-gray;
    margin-bottom: 0px;

    i {
      font-size: 4rem;
    }
  }
}
