/*--------------------------------

ark-icons icon font
Generated using nucleoapp.com

-------------------------------- */

$ark-font-path: "~assets/fonts" !default;

@font-face {
  font-family: 'ark-icons';
  src: url('#{$ark-font-path}/ark-icons.eot');
  src: url('#{$ark-font-path}/ark-icons.eot') format('embedded-opentype'),
       url('#{$ark-font-path}/ark-icons.woff2') format('woff2'),
       url('#{$ark-font-path}/ark-icons.woff') format('woff'),
       url('#{$ark-font-path}/ark-icons.ttf') format('truetype'),
       url('#{$ark-font-path}/ark-icons.svg') format('svg');
}

/* base class */
.ark-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'ark-icons';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-bold{
    font-weight: 700;
  }
}

/* size examples - relative units */
.ark-icon-sm {
  font-size: 0.8em;
}

.ark-icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.ark-icon-16 {
  font-size: 16px;
}

.ark-icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.ark-icon-is-spinning {
  animation: ark-icon-spin 1s infinite linear;
}

@keyframes ark-icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.ark-icon-rotate-90  {
	transform: rotate(90deg);
}

.ark-icon-rotate-180 {
	transform: rotate(180deg);
}

.ark-icon-rotate-270 {
	transform: rotate(270deg);
}

.ark-icon-flip-y {
	transform: scaleY(-1);
}

.ark-icon-flip-x {
	transform: scaleX(-1);
}

/* icons */
$icons: (
        'calendar-64': 'ea02',
        'calendar': 'ea04',
        'building-64': 'ea05',
        'building': 'ea06',
        'folder-64': 'ea07',
        'folder': 'ea08',
        'cogwheel-64': 'ea0b',
        'cogwheel': 'ea0c',
        'profile': 'ea0e',
        'reading-64': 'ea0f',
        'reading': 'ea10',
        'presentation-64': 'ea11',
        'presentation': 'ea12',
        'statistics-64': 'ea13',
        'statistics': 'ea14',
        'database-64': 'ea15',
        'database': 'ea16',
        'row-table': 'ea18',
        'data-table': 'ea19',
        'cloud-data-sync-64': 'ea1b',
        'cloud-data-sync': 'ea1c',
        'archive-drawer': 'ea1d',
        'preferences': 'ea1e',
        'cloud-upload': 'ea1f',
        'folder-chart-pie': 'ea20',
        'upload-data': 'ea21',
        'download-data': 'ea22',
        'data-settings': 'ea23',
        'district': 'ea24',
        'district-24': 'ea25',
        'data-settings-24': 'ea26',
        'download-data-24': 'ea27',
        'upload-data-24': 'ea28',
        'folder-chart-pie-24': 'ea29',
        'upload-file-24': 'ea2a',
        'cloud-upload-24': 'ea2b',
        'preferences-24': 'ea2c',
        'archive-drawer-24': 'ea2d',
        'cloud-data-sync-32': 'ea2e',
        'data-table-24': 'ea2f',
        'row-table-24': 'ea30',
        'database-24': 'ea31',
        'statistics-24': 'ea32',
        'presentation-24': 'ea33',
        'reading-24': 'ea34',
        'folder-24': 'ea36',
        'building-24': 'ea37',
        'calendar-24': 'ea38',
        'profile-24': 'ea39',
        'folder-settings': 'ea3a',
        'file-settings-64': 'ea3b',
        'file-settings': 'ea3c',
        'file-settings-24': 'ea3d',
        'folder-settings-24': 'ea3e',
        'folder-settings-64': 'ea3f',
        'cogwheel-24': 'ea40',
);


@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

@each $name, $code in $icons {
  .ark-#{""+$name}::before {
    content: unicode($code);
  }
}
