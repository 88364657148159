

@media (max-width: 850px) {
  .column-width {
    width: 50%;
  }
}
input.column-filter {
  width: 65%;
}
.pagination-item {
  margin: 0 5px;
}
