.bot-icon {
  width: 32px;
  height: auto;
  margin-left: 8px;
  vertical-align: middle;
}

.loading-icon {
  width: 50px;
  height: 50px;
  display: block;
  margin: 15px auto;
  color: rgb(30 41 59) !important;
}

.sql-box {
  margin-top: 15px;
  border: 1px solid #eee;
  min-height: 300px;
  max-height: 300px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds a shadow */
  background-color: #f3f5f7;;
}

.chat-window {
  background-color: #f3f5f7; /* A light grayish color, you can adjust this */
  padding: 15px;
  max-height: 300px; /* Or whatever max height you'd prefer */
  overflow-y: auto;
  min-height: 300px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds a shadow */
  margin-bottom: 15px; /* Spacing between the chat window and the input below */
}



.chat-header {
  background-color: rgb(255 255 255); /* Bootstrap primary blue, change as needed */
  color: rgb(30 41 59);  /* White text color */
  padding: 15px;
  text-align: center;
  font-size: 1.5rem;  /* Increase font size for header */
  font-weight: bold;  /* Bold font weight */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds a shadow */
  margin-bottom: 20px;  /* Space between the header and the next row */
}
.chat-input {
  margin-top: 10px;
  border-bottom: 1px solid #E3E3E3 !important;
  border-left: 1px solid #E3E3E3 !important;
  border-top: 1px solid #E3E3E3 !important;
  border-right: none !important;

}
.submit-button {
  background-color: rgb(255 255 255) !important;
  color: rgb(30 41 59) !important;
  border-bottom: 1px solid #E3E3E3 !important;
  border-right: 1px solid #E3E3E3 !important;
  border-top: 1px solid #E3E3E3 !important;
  border-left: none !important;
}
.w-6{
  width: 1.5rem !important;
}
.h-6{
  height: 1.5rem !important;
}
/* Optional: If you want to increase the spacing between your rows */
.row {
  margin-bottom: 15px;
}
.hamburger-icon {
  cursor: pointer;
  float: left; /* Moved to the left side */
  font-size: 1.5rem;
  margin-right: 10px;
}

