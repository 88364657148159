.sidebar{
  background-color: rgb(0, 117, 165,.50);

}
.sidebar .sidebar-wrapper{
  background-color: rgb(0, 117, 165,.50);
  overflow-x: hidden;
}
.sidebar .logo .logo-img img.sidebar-mini{
  float: left;
  //width: 80%;
  //height: 100%;
  justify-content: center;
  text-align: center;
  //margin-left: 23px;
  //margin-right: 23px;
  padding-right: 20%;
  padding-left: 10%;
  //padding-left: .5rem;
  position: relative;
}
/*
.sidebar .logo .logo-img .sidebar-normal{
  display: block;
  opacity: 1;
}*/
/*.sidebar .logo{
  color: rgb(255, 255, 255);
  justify-content: center; // Add this for horizontal centering
  align-items: center; // Add this for vertical centering
  align-content: center;
  height: 100%;
  width: 125%;
}
.sidebar .logo .logo-img{
  padding-bottom: 3rem;
  height: 100%;


}
.sidebar .logo .logo-img img{
  max-width: 100%;
  padding-bottom: 10px;
  padding-right: 1rem;
}*/

.icon-mini {
  text-transform: uppercase;
  float: left;
  width: 30px;

  //text-align: center;
  // margin-right: 15px;
  letter-spacing: 1px;
}
