
.form-field-width{
  width: 100%;
  //overflow: scroll;
}
@media (max-width: 768px) {
  .form-field-width {
    width: 100%;
   // overflow: scroll;
  }
  .form-sweet-alert{
    display: block !important;
    //overflow: scroll !important;
    height: 100% !important;
    margin: auto !important;

  }

}
form-sweet-alert{
  display: block !important;
  //overflow: scroll !important;
  height: 100% !important;
  width: 100%;
  margin: auto !important;
}
.react-select__menu {
  z-index: 7000 !important;
}/*

.sweet-alert p {
  z-index: unset !important;
}*/
textarea {
  width: 100%;
  height: 700px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; /* Allows the user to resize the textarea vertically */
}
.sweet-alert {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  max-height: 90vh !important;
  max-width: 80vh !important;
  width: 80% !important;
  .container{
    display: flex;
    flex-direction: column;
    overflow-x: auto !important;
    overflow-y: auto !important; // Changed to auto to only show scrollbar when necessary
    width: 100% !important;
    max-height: 50vh !important;
  }
  h2 {
    flex-shrink: 0 !important;
    margin: 0 20px;
    padding: 1rem;
    min-height: 4rem;
  }

 p {
   flex-shrink: 0 !important;
   margin: 0 20px;
   padding: 1rem;
   min-height: 4rem;
  }
}





