@import "../../assets/scss/lbd/_variables.scss";
.nav-tabs .nav-link {
  border: 2px solid transparent;
  //border-color: #007bff;
  transition: border-color 0.2s; /* Optional: For smooth transition */
  color: $ark-blue;
}

 .nav-tabs .nav-link:hover {
  border-color: darkgrey; /* Outline color for hover effect */
   background-color: lightgrey;

}

.nav-tabs .nav-link.active {
  border-color: $ark-blue;  /* Outline color for active tab */

}
